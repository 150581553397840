<template>
  <loading :active="loading" :is-full-page="true"></loading>

  <esg-header></esg-header>

  <main>
    <carousel-cards></carousel-cards>

    <div class="description">
      <h2 style="text-transform: uppercase">DIAGNÓSTICO ESG</h2>
      <h3>
        Nosso objetivo é melhor entender o contexto de integração e adoção das
        práticas ESG à estratégia e operação das pequenas e médias empresas.
        Coma sua contribuição, será possível apoiarmos as PMEs à gerarem
        resultados mais positivos para a própria organização e para os seus
        públicos estratégicos. Vamos começar?
      </h3>
    </div>

    <div class="main-content justify-content-between">
      <form @change="validateForm()">
        <div class="card mb-3" v-for="axle in axles" :key="axle">
          <div class="card-header">{{ axle }}</div>
          <div class="card-body">
            <div class="">
              <div class="mb-3" v-for="question in quiz[axle]" :key="question">
                <h6 class="form-label required">
                  {{ question.numberQuestion }}. {{ question.name }}
                </h6>
                <div
                  v-show="question.answer.answerId == null"
                  class="field-error text-secondary mb-2"
                >
                  Favor, selecionar uma opção.
                </div>
                <label
                  class="label-radio mb-1"
                  v-bind:class="{
                    'option-selected': question.answerId === option.id,
                  }"
                  v-for="option in question.options"
                  :key="option"
                >
                  <input
                    type="radio"
                    v-model="question.answer.answerId"
                    :value="option.id"
                    :name="question.questionId"
                  />
                  {{ option.name }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>

  <footer>
    <img src="../images/barra_ods.svg" alt="Barra" class="w-100" />

    <div class="footer-items">
      <powered-by class="text-center"></powered-by>
      <button
        @click="save()"
        type="button"
        class="btn-seall"
        :disabled="!canSave"
      >
        Salvar
      </button>
    </div>
  </footer>
</template>

<script>
import { groupBy } from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service.js";
import CarouselCards from "./CarouselCards.vue";
import EsgHeader from "./EsgHeader.vue";
import PoweredBy from "./PoweredBy.vue";
export default {
  name: "Perguntas",
  components: {
    Loading,
    EsgHeader,
    PoweredBy,
    CarouselCards,
  },
  data() {
    return {
      cogId: null,
      loading: false,
      organization: null,
      quiz: null,
      axles: [],
      canSave: false,
    };
  },
  mounted() {
    this.$i18n.locale = "pt";
    this.cogId = AuthService.getCogId();
    this.loading = true;
    OrganizationService.getG4Quiz(this.cogId)
      .then((org) => {
        this.quiz = groupBy(org.data, (q) => q.axle);
        this.axles = Object.keys(this.quiz);

        this.validateForm();

        if (this.canSave) {
          window.location = "/esg/radar";
        }

        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$notify({
          title: this.$t("common.error_http"),
          type: "error",
        });
      });
  },
  methods: {
    validateForm() {
      this.canSave =
        this.quizQuantityNotAnswered().length === this.axles.length;
    },
    quizQuantityNotAnswered() {
      return this.axles.filter((t) => {
        return !this.quiz[t].some((q) => q.answer.answerId == null);
      });
    },
    save() {
      this.loading = true;
      let quiz = [];
      this.axles.forEach((t) => quiz.push(...this.quiz[t]));

      OrganizationService.saveG4Quiz(this.cogId, quiz)
        .then((org) => {
          this.loading = false;
          this.$toast.success("Questionário finalizado com sucesso!", {
            type: "success",
            duration: false,
          });
          window.location = "/esg/radar";
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
            duration: -100,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "../css/bootstrap/bootstrap.min.css";
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px lightgray;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #492e85;
  border-radius: 5px;
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

main {
  background-color: #e5e5e5;
  position: relative;

  .description {
    background: #ffffff;
    padding: 5vh 10vw;
    h2 {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 133%;
      text-align: center;
      color: #533a8b;
      margin: 0vh 0vw;
      padding: 5vh 0vw 0vh 0vw;
    }

    h3 {
      color: #ffffff;
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 125%;
      text-align: center;
      color: #a3a3a3;
      text-align: center;
      margin: 0vh 0vw;
      padding: 2vh 0vw 0vh 0vw;
    }
  }

  .main-content {
    padding: 5vh 15vw;
    background: #ffffff;
    .card-header {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      color: #533a8b;
      background-color: #e5e5e5;
    }

    form {
      .label-radio {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #a3a3a3;

        &:hover input ~ .checkmark {
          background-color: #ccc;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          &:checked ~ .checkmark {
            background-color: #533a8b;
          }
          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #eee;
          border-radius: 50%;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }

        .checkmark:after {
          top: 7px;
          left: 7px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
        }
      }
      .option-selected {
        font-weight: 500;
        color: #533a8b;
      }
    }
  }
}

footer {
  height: 0vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;

  .footer-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0vh 20vw;
  }
  .btn-seall {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 5vh 0vw;
    padding: 1vh 3vw;
  }
}

.v--default-css .c-toast--success {
  background-color: #492e85;
}
</style>